import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { logo } from "../../assets/images";
import { SocialIcon } from "../../components/index";
import { signup } from "../../store/actions";
import SignupForm from "./Form";
import "./SignUp.scss";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmpassword: "",
      isLoading: false,
      region: "",
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = () => {
    const { history, t } = this.props;
    debugger;
    const { email, password, confirmpassword, region } = this.state;
    if (!email || !password || !confirmpassword) {
      toast.error(t("form_fields_are_required"), {
        toastId: "requiredFields"
      });
      return false;
    }

    if (password.trim() !== confirmpassword.trim()) {
      toast.error(t("new_password_and_confirm_password_do_not_match"), {
        toastId: "confirmPassword"
      });
      return false;
    }
    this.setState({ isLoading: true });
    this.props
      .signup(email, password)
      .then(() => {
        toast.success(t("Signup Success"));
        history.replace("/login");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { email, password, confirmpassword, isLoading } = this.state;

    const { isAuthenticated, t } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/welcome" />;
    }

    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center  signup banner-primary">
          <div className="login__logo ">
            <img src={logo} />
          </div>
          <SignupForm t={t} />
          <div className="signup__description">
            <Link className="d-block text-center btn-link pt-3" to="#">
              {this.props.t("privacy")}
            </Link>
            <SocialIcon />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.userAuth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  signup: (email, password) => dispatch(signup(email, password)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SignUp));
