import { fetchUtil } from "../../helpers/fetchUtil";

export const ADD_DEVICE_REQUEST = "ADD_DEVICE_REQUEST";
export const ADD_DEVICE_FAIL = "ADD_DEVICE_FAIL";
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS";

export const GET_DEVICE_REQUEST = "GET_DEVICE_REQUEST";
export const GET_DEVICE_FAIL = "GET_DEVICE_FAIL";
export const GET_DEVICE_SUCCESS = "GET_DEVICE_SUCCESS";

export const DEVICE_LIST_REQUEST = "DEVICE_LIST_REQUEST";
export const DEVICE_LIST_FAIL = "DEVICE_LIST_FAIL";
export const DEVICE_LIST_SUCCESS = "DEVICE_LIST_SUCCESS";

export const EDIT_DEVICE_REQUEST = "EDIT_DEVICE_REQUEST";
export const EDIT_DEVICE_FAIL = "EDIT_DEVICE_FAIL";
export const EDIT_DEVICE_SUCCESS = "EDIT_DEVICE_SUCCESS";
export const FORGET_DEVICE_SUCCESS = "FORGET_DEVICE_SUCCESS";

export const SETUP_DEVICE = "SETUP_DEVICE";

export const SET_CURRENT_STATE_DEVICE = "SET_CURRENT_STATE_DEVICE";
export const SET_SESSION_DATA = "SET_SESSION_DATA";

export const SET_CURRENT_DEVICE = "SET_CURRENT_DEVICE";

export const SET_SESSION_BEGIN = "SET_SESSION_BEGIN";
export const CLEAR_SESSION_DATA = "CLEAR_SESSION_DATA";

export const SET_SMART_PATH_DATA = "SET_SMART_PATH_DATA";

export const SET_INITIAL_SMART_PATH_DATA = "SET_INITIAL_SMART_PATH_DATA";

export const SET_DEVICE_INFORMARION = "SET_DEVICE_INFORMARION";

export const CLEAR_DEVICE_STATE = "CLEAR_DEVICE_STATE";

export const DEVICE_CONNECTED = "DEVICE_CONNECTED";

export const DEVICE_DISCONNECTED = "DEVICE_DISCONNECTED";

export const SET_CURRENT_DEVICE_STATISTIC = "SET_CURRENT_DEVICE_STATISTIC";

export const CONNECTING = "CONNECTING";

export const CONNECTION_ERROR = "CONNECTION_ERROR";

export const SET_SMARTH_PATH_TITLE = "SET_SMARTH_PATH_TITLE";

export const SET_SMARTH_PATH_INDEX = "SET_SMARTH_PATH_INDEX";

export const getDevice = (deviceId) => (dispatch, getState) => {
  dispatch({ type: GET_DEVICE_REQUEST });
  return fetchUtil({
    url: `/${deviceId}`,
    token: getState().userAuth.user.token,
  })
    .then((res) => {
      dispatch({ type: GET_DEVICE_SUCCESS, payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: GET_DEVICE_FAIL });
      return Promise.reject(err);
    });
};

export const getDevices = (userId) => (dispatch, getState) => {
  dispatch({ type: DEVICE_LIST_REQUEST });
  return fetchUtil({
    url: `/${userId}/devices`,
    token: getState().userAuth.user.token,
  })
    .then((res) => {
      dispatch({ type: DEVICE_LIST_SUCCESS, payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: DEVICE_LIST_FAIL });
      return Promise.reject(err);
    });
};

export const addDevice = (id, bodyData) => (dispatch, getState) => {
  const token = getState().userAuth.user.token;
  return fetchUtil({
    url: `/${id}/devices`,
    method: "POST",
    body: JSON.stringify(bodyData),
    token,
  })
    .then((res) => {
      dispatch({ type: ADD_DEVICE_SUCCESS, payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const editDevice = (id, bodyData, override) => (dispatch, getState) => {
  const token = getState().userAuth.user.token;
  return fetchUtil({
    url: `api/Devices/${id}`,
    method: "PUT",
    body: JSON.stringify(bodyData),
    token,
    externalApi: true,
  })
    .then((res) => {
      dispatch({
        type: EDIT_DEVICE_SUCCESS,
        payload: bodyData,
        override: override,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const forgetDevice = (id, bodyData) => (dispatch, getState) => {
  let payload = { ...bodyData, forgotten: true };
  const token = getState().userAuth.user.token;
  return fetchUtil({
    url: `api/Devices/${id}`,
    method: "PUT",
    body: JSON.stringify(payload),
    token,
    externalApi: true,
  })
    .then((res) => {
      dispatch({ type: FORGET_DEVICE_SUCCESS, payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
